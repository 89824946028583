<template>
  <div>
    <h1 class="mb-5">Журнал действий</h1>
    <v-row>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
        style="width: 300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            placeholder="Заезд - выезд"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            height="48"
            style="width: 300px"
            v-on="on"
            hide-details
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          :first-day-of-week="1"
          no-title
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(dates)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-autocomplete
        v-model="company_id"
        :items="companies"
        dense
        height="48"
        outlined
        label="Туроператор"
        item-value="id"
        item-text="name"
        @update:search-input="getCompanies"
      ></v-autocomplete>
      <v-select v-model="city" :items="cities" dense height="48" outlined label="Направление" item-value="slug" item-text="name"></v-select>
      <v-btn color="primary" @click="getList" dense height="48">Поиск</v-btn>
    </v-row>

    <v-data-table
      class="mt-5"
      :headers="headers"
      :items="items.results"
      :loading="loading"
      disable-sort
      :options.sync="options"
      :server-items-length="items.count"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ moment(new Date(item.created_at * 1000)).format("DD.MM.YYYY") }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ `${item.start}-${item.end}` }}
      </template>
      <template v-slot:[`item.count`]="{ item }">
        {{ `Детей: ${item.child_count}, Взрослых: ${item.adult_count}` }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import pagination from "@/utils/pagination";
import axios from "axios";
import moment from "moment";
import { accommodationService } from "@/services/accommodation.service.js";
export default {
  mixins: [pagination],
  data() {
    return {
      items: [],
      moment: moment,
      company_id: null,
      companies: [],
      headers: [
        { text: "Дата создания", value: "created_at" },
        { text: "Дата", value: "date" },
        { text: "Компания", value: "company" },
        { text: "Сотрудник", value: "user" },
        { text: "Туроператор", value: "tour_operator" },
        { text: "Количество комнат", value: "room_count" },
        { text: "Места", value: "count" },
        { text: "Город", value: "city" },
        { text: "Тип", value: "accommodation_types" },
        // { text: 'Сотрудник', value: 'user' },
      ],
      loading: false,
      city: null, 
      cities: [], 
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(new Date().getTime() + 86400000).toISOString().substr(0, 10),
      ],
      menu: false,
    };
  },
  computed: {
    dateRangeText() {
      if (this.dates.length == 2) {
        if (this.dates[0] > this.dates[1]) this.dates.reverse();
      }
      return this.dates.join(" ~ ");
    },
  },
  created() {
    this.getCompanies();
    this.getCities()
  },
  methods: {
    async getCities() {
      this.cities = await accommodationService.getCities();
    },
    async getCompanies(value) {
      let params = {
        page: this.options.page,
        size: this.options.itemsPerPage,
        search: value,
      };
      let res = (await axios.get("/logs/companies/", { params })).data;
      this.companies = res.results;
    },
    async getList() {
      this.loading = true;
      let params = {
        page: this.options.page,
        size: this.options.itemsPerPage,
        start: this.dates[0],
        end: this.dates[1],
        company_id: this.company_id,
        city: this.city
      };
      this.items = (await axios.get("/logs/", { params })).data;
      this.loading = false;
    },
  },
};
</script>