var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Журнал действий")]),_c('v-row',[_c('v-menu',{ref:"menu",staticStyle:{"width":"300px"},attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"width":"300px"},attrs:{"placeholder":"Заезд - выезд","prepend-inner-icon":"mdi-calendar","readonly":"","height":"48","hide-details":"","outlined":"","dense":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"no-title":"","range":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Отмена ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1),_c('v-autocomplete',{attrs:{"items":_vm.companies,"dense":"","height":"48","outlined":"","label":"Туроператор","item-value":"id","item-text":"name"},on:{"update:search-input":_vm.getCompanies},model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}}),_c('v-select',{attrs:{"items":_vm.cities,"dense":"","height":"48","outlined":"","label":"Направление","item-value":"slug","item-text":"name"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('v-btn',{attrs:{"color":"primary","dense":"","height":"48"},on:{"click":_vm.getList}},[_vm._v("Поиск")])],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.items.results,"loading":_vm.loading,"disable-sort":"","options":_vm.options,"server-items-length":_vm.items.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(new Date(item.created_at * 1000)).format("DD.MM.YYYY"))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.start) + "-" + (item.end)))+" ")]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("Детей: " + (item.child_count) + ", Взрослых: " + (item.adult_count)))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }